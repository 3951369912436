import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'
import store from '@/store/store'
import router from '@/router'

const axios_instance = axios.create({
  baseURL:apiBaseUrl,
});

var isRefreshing = false

//Add a response interceptor
axios_instance.interceptors.response.use(
  function (response) {
    store.dispatch('AUTH_INSPECT')
      .catch(() => {
        store.commit('AUTH_LOGOUT')
        router.push('/login')
      })
    return response
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && originalRequest.url === apiBaseUrl +'/auth/token/refresh') {
     router.push('/login');
     return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true
        originalRequest._retry = true
        const refreshToken = JSON.parse(store.state.auth.token).refresh

        return axios_instance.post(apiBaseUrl +'/auth/token/refresh', {"refresh": refreshToken})
         .then(res => {
           if (res.status === 200) {
             store.commit('AUTH_REFRESH', res.data)
             originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access
             return axios_instance(originalRequest)
           }
           else {
             store.commit('AUTH_LOGOUT')
             router.push('/login')
           }
         })
         .catch(() => {
           store.commit('AUTH_LOGOUT')
           router.push('/login')
         })
         .finally(() => {
           isRefreshing = false
         })
     }
     originalRequest.headers['Authorization'] = 'Bearer ' + JSON.parse(store.state.auth.token).access
     return axios_instance(originalRequest)
   }
   return Promise.reject(error)
});

export class APIConseillerConnected {

  getAllSalariesAttributedByState (token, url) {

    /* Be careful in this page because a URL with the page is needed !!! */

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getSalarie (token, id) {
    const url = apiBaseUrl + `/salarie/` + id
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getSalarieDocument (token, salarie_pk, type) {
    // TYPE is a string : ID 
    const url = apiBaseUrl + `/get-salarie-document/` + salarie_pk + '/' + type
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getSalarieProtectedDocument (token, document_pk) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + '/salarie-document/protected/file/' + document_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }

  validateSalarie (token, salarie_pk) {
    const url = apiBaseUrl + `/salarie/validate/` + salarie_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {}
    return axios_instance.put(url, data, {'headers': headers,})
  }

  refuseSalarie (token, salarie_pk, refused_message) {
    const url = apiBaseUrl + `/salarie/refuse/` + salarie_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'refused_message': refused_message
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }

  saveMuna (token, beneficiaire_pk, muna) {
    const url = apiBaseUrl + `/conseiller-muna/` + beneficiaire_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'muna': muna
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }


  // -------------- BRI -------------
  getSalarieBRI (token, salarie_pk) {
    const url = apiBaseUrl + `/get-salarie-bri/` + salarie_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  uploadSalarieBRI (token, bri_pk, file) {
    const url = apiBaseUrl + `/modify-bri/` + bri_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }
    var formData = new FormData()
    formData.append('file', file);
    return axios_instance.put(
      url, formData,
      {
        'headers': headers,
      }
    )
  }

  getSalarieProtectedBRI (token, bri_pk) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + '/salarie-bri/protected/file/' + bri_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }

  // ---------- Appointments -----------
  getAttributedAppointments (token) {
    const url = apiBaseUrl + `/appointments-attributed-list/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }
}
